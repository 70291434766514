<template>

  <div class="grid">
    <div class="col-12 md:col-6">
      <h3><i class="pi pi-shopping-cart text-2xl"></i> Carrinhos Abertos</h3>
    </div>
  </div>

  <Panel class="mt-2">

    <template #header>
      <div class="font-medium">
        Filtros
      </div>
    </template>

    <div class="grid p-fluid">

      <div class="p-field md:col-3">
        <label for="periodo">Período:</label>
        <Calendar class="mt-1" v-model="periodo" selectionMode="range" dateFormat="dd/mm/yy"/>
      </div>

      <div class="p-field md:col-2">
        <Button
          id="filtrar"
          label="Filtrar"
          icon="pi pi-check"
          class="p-button-success mt-4"
          style="width: auto"
          @click="filtrar"
        ></Button>
      </div>

    </div>

  </Panel>

  <Panel class="mt-4">

    <template #header>
      <div class="font-medium">
      </div>
    </template>

    <div class="grid">
      <div class="col-12">

        <DataTable :value="carrinhos"  :lazy="true" :loading="loading" responsiveLayout="scroll" v-model:expandedRows="carrinhosExpandidos">
          
          <Column :expander="true" headerStyle="width: 3rem" />
          <Column class="col-data" field="data" header="Data de Criação">
            <template #body="slotProps">
                {{ $utils.formatDate(slotProps.data.data) }}
            </template>
          </Column>
          <Column class="col-cliente-nome" field="usuario.nome" header="Cliente"></Column>
          <Column class="col-cliente-fone" field="usuario.fone" header="Telefone"></Column>
          <Column class="col-cliente-email" field="usuario.email" header="E-Mail"></Column>
          
          <template #expansion="{data}">
            <DataTable :value="data.listaProdutos" :paginator="false">
              <Column class="col-produto-nome" field="nome" header="Produto"></Column>
              <Column class="col-produto-quantidade" field="qnt" header="Quantidade"></Column>
              <Column class="col-produto-valor" field="valor" header="Valor">
                <template #body="slotProps">
                  {{ $utils.formatCurrency(slotProps.data.valor) }}
                </template>
              </Column>
            </DataTable>
          </template>

          <template #empty>
            Nenhum carrinho encontrado.
          </template>

          <template #footer>

            <div class="totalizadores">
              <span>Carrinhos: </span>
              <span>{{ totalizadores.total }}</span>
            </div>

            <div class="totalizadores">
              <span>Identificados: </span>
              <span>{{ totalizadores.identificados }}</span>
            </div>

            <div class="totalizadores">
              <span>Anônimos: </span>
              <span>{{ totalizadores.anonimos }}</span>
            </div>

          </template>

        </DataTable>
        
      </div>
    </div>

  </Panel>

  <Toast position="top-right" />

</template>

<script>

import moment from 'moment-timezone';

export default {
  data() {
    return {
      //Controle de Apresentação
      loading: false,
      carrinhosExpandidos: [],

      // Campos do Formulário
      periodo: null,
      loja:null,

      // Dados cadastrados
      carrinhos: [],
      totalizadores: {
        total: 0,
        identificados: 0,
        anonimos: 0,
      }
    };
  },

  watch: {

    carrinhos: function(carrinhos) {

        for(const c of carrinhos) {

            if(c.usuario.nome != "Cliente Anônimo")
                this.totalizadores.identificados++;
            else
                this.totalizadores.anonimos++;

        }

        this.totalizadores.total = carrinhos.length;

    },
    '$auth.usuario.loja': {
      handler: function (val) {
        this.loja = this.$utils.getStdObject(val);
      },
      deep: true,
      immediate: true
    },

  },

  methods: {

    async filtrar() {

      let data_inicial;
      if(this.periodo != null && this.periodo[0] != null) {
        data_inicial = moment(this.periodo[0]).tz("America/Sao_Paulo").format("YYYY-MM-DD");
      } else {
          
        this.$toast.add({
          severity: "error",
          summary: "ERRO!",
          detail: "A data inicial precisa ser informada!",
          life: 5000,
        });

        return;
      }

      let data_final;
      if(this.periodo[1] != null) {
        data_final = moment(this.periodo[1]).tz("America/Sao_Paulo").format("YYYY-MM-DD");
      } else {
        data_final = data_inicial;
      }

      this.loading = true;

      const query = "&data=~" + data_inicial + "," + data_final+"&loja.id="+this.loja.id;

      const response = await this.$api.get('/pedidos?status=' + query);
      const data = response.data;

      if(data.success) {

        this.carrinhos = data.data;

      } else {

        console.log(data);

        this.$toast.add({
          severity: "error",
          summary: "ERRO!",
          detail: data.error.message,
          life: 5000,
        });

      }

      this.loading = false;
        
    },

  },
  
};
</script>

<style lang="scss" scoped>

  .totalizadores {
    width: 20%;
    display: inline-block;
  }

</style>